.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .selectGame {
    margin-left: 30px;
    max-width: 300px;
    flex: 1;
  }
}

.content {
  margin-top: 20px;
}

.hashGameFormContainer {
  padding: 0;
  .groupInput {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .button {
      margin-top: 20px;
    }
  }
}
