.list {
}

.gameItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid grey;
  padding: 16px;
  margin: 20px !important;
  min-width: 300px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    background: #f1f0f0;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .name {
      flex: 1;
    }
    .status {
      padding: 12px;
      height: 30px;
      min-width: 100px;
      border-radius: 15px;
      background-color: aliceblue;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .body {
    margin-top: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .field {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        color: gray;
      }
    }
  }
  .footer {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.filterContainer {
  width: 100%;

  .blank {
    flex: 1;
  }

  > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      width: 100%;
    }
  }

  .filterLayler {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
